/******** images slider**********/
import slider5 from '../img/slider/slider5.webp';

import sliderjpg1 from '../img/slider/slider1.jpg';
import sliderjpg2 from '../img/slider/slider2.jpg';
import sliderjpg3 from '../img/slider/slider3.jpg';
import sliderjpg4 from '../img/slider/slider4.jpg';
import sliderpng5 from '../img/slider/slider5.png';
import sliderjpg6 from '../img/slider/slider6.jpg';

import 'tw-elements';

const Slider = ({name, version}) => {

    return(
        <section className='w-full'>
            <div id='inicio' className="w-screen md:w-full h-[108px;]"></div>
            <div id="carouselExampleControls" className="carousel slide relative h-[200px] md:h-screen" data-bs-ride="carousel">
            <div className="carousel-inner relative w-screen md:w-full h-[200px] md:h-screen overflow-hidden">
                <div className="carousel-item active h-[200px] md:h-screen relative float-left">
                <img
                    src={sliderjpg1}
                    className="block w-screen md:w-full h-[200px] md:h-screen"
                    alt="Toldos"
                />
                </div>
                <div className="carousel-item h-[200px] md:h-screen relative float-left w-screen md:w-full">
                <img
                    src={sliderjpg2}
                    className="block w-screen md:w-full h-[200px] md:h-screen"
                    alt="Sombrillas"
                />
                </div>
                <div className="carousel-item h-[200px] md:h-screen relative float-left w-screen md:w-full">
                <img
                    src={sliderjpg3}
                    className="block w-screen md:w-full h-[200px] md:h-screen"
                    alt="Tumbonas"
                />
                </div>
                <div className="carousel-item h-[200px] md:h-screen relative float-left w-screen md:w-full">
                <img
                    src={sliderjpg4}
                    className="block w-screen md:w-full h-[200px] md:h-screen"
                    alt="Pergolas"
                />
                </div>
                <div className="carousel-item h-[200px] md:h-screen relative float-left w-screen md:w-full">
                <img
                    src={(name !== 'safari') ? slider5 : sliderpng5}
                    className="block w-screen md:w-full h-[200px] md:h-screen"
                    alt="SMEG"
                />
                </div>
                <div className="carousel-item h-[200px] md:h-screen relative float-left w-screen md:w-full">
                <img
                    src={sliderjpg6}
                    className="block w-screen md:w-full h-[200px] md:h-screen"
                    alt="Mesas"
                />
                </div>
            </div>
            <button
                className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
            >
                <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
            >
                <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
            </div>
        </section>
    )
}

export default Slider