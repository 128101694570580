import { NavLink } from "react-router-dom";
import {AiFillPhone} from 'react-icons/ai';
import {FaWhatsapp} from 'react-icons/fa';
import {BsHandIndexThumb} from 'react-icons/bs';

const Velas = ({library}) => {
    const redirectTel = () => {
        window.open("tel:+50223882930", "_blank", "noopener,noreferrer");
    }
    const redirectWhatsapp = () => {
        window.open("https://wa.me/+50240546311","_blank", "noopener,noreferrer");
    }
    return (
        <section className="min-h-[500px] w-full mb-16 overflow-hidden">
            <div id='inicio' className="w-full h-[144px;]"></div>
            <div className="max-w-[1140px] m-auto w-full p-4">
                <h2 className='under-first-leter text-[var(--title-home-color)] text-left tracking-wider text-3xl uppercase'>{library.title[0]}</h2>
                <div className="w-full mt-1 pb-7">
                    <span className="subraydo flex"></span>
                </div>
                <div className='min-h-[50px] flex justify-center items-center gap-2 pt-5 mb-1 sx:flex-wrap '>
                    <div className='aspect-w-16 aspect-h-9'>
                    <img className='w-full h-full object-cover relative'
                        src={library.title[1].img}
                        alt='velas'
                        />
                    </div>
                    <div className='aspect-w-16 aspect-h-9'>
                        <p className="text-[var(--detail-home-color)] font-medium tracking-widest text-lg mb-5">
                            {library.history[0]}
                        </p>
                        <p className="text-[var(--detail-home-color)] font-medium tracking-widest text-lg mb-5">
                            {library.history[1]}
                        </p>
                        <p className="text-[var(--detail-home-color)] font-medium tracking-widest text-lg mb-5">
                            {library.history[2]}
                        </p>
                    </div>
                </div>
                <div className='grid grid-cols-1 pb-5'>
                    <NavLink to="/contactanos" className="flex justify-center mt-3 px-5 py-3 max-w-[10rem] text-center border-solid border-2 border-[var(--primary-information)] text-[var(--primary-information)] hover:bg-[var(--primary-information)] hover:text-white">
                        <span className='mr-2'>Contáctanos</span> <BsHandIndexThumb size={18}/>
                    </NavLink>
                </div>
                <div className='w-full flex justify-center gap-2 mb-5'>
                    <button onClick={redirectTel} className="text-[var(--primary-information)] flex"> <AiFillPhone size={18} className='mr-2'/> <span>Llámanos</span></button>
                    <button onClick={redirectWhatsapp} className="text-[var(--primary-information)] flex"> <FaWhatsapp size={18} className='mr-2'/> <span>Escríbenos</span></button>
                </div>
                {library.images.map((img, index) => {
                return <div key={index} className={`grid ${img.align ? "grid-cols-1" : "grid-cols-2 gap-1" } mb-1`}>
                            {
                                img.align && (
                                    <div className="aspect-w-1 aspect-h-1">
                                        <img src={img.img} alt="product" className="w-full"/>
                                    </div>
                                )
                            }
                            {
                                !img.align && img.img.map((img, index) => {
                                    return <div key={index} className="aspect-w-1 aspect-h-1">
                                                <img src={img} className="h-full w-full" alt="product" />
                                            </div>
                                })
                            }
                        </div>
                    })}
            </div>
        </section>
    )
}

export default Velas