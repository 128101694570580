import { NavLink } from "react-router-dom";
import {AiFillPhone} from 'react-icons/ai';
import {FaWhatsapp} from 'react-icons/fa';
import {BsHandIndexThumb} from 'react-icons/bs';

import muebles1 from '../img/muebles/muebles5.webp';
import muebles2 from '../img/muebles/muebles1.webp';
import muebles3 from '../img/muebles/muebles2.webp';
import muebles4 from '../img/muebles/muebles3.webp';
import muebles5 from '../img/muebles/muebles4.webp';
import muebles6 from '../img/muebles/muebles6.webp';
import muebles7 from '../img/muebles/muebles7.webp';
import muebles8 from '../img/muebles/muebles8.webp';
import muebles9 from '../img/muebles/muebles9.webp';
import muebles10 from '../img/muebles/muebles10.webp';
import muebles11 from '../img/muebles/muebles11.webp';
import muebles12 from '../img/muebles/muebles12.webp';

import mueblespng1 from '../img/muebles/muebles5.png';
import mueblespng2 from '../img/muebles/muebles1.png';
import mueblespng3 from '../img/muebles/muebles2.png';
import mueblespng4 from '../img/muebles/muebles3.png';
import mueblespng5 from '../img/muebles/muebles4.png';
import mueblespng6 from '../img/muebles/muebles6.png';
import mueblespng7 from '../img/muebles/muebles7.png';
import mueblespng8 from '../img/muebles/muebles8.png';
import mueblespng9 from '../img/muebles/muebles9.png';
import mueblespng10 from '../img/muebles/muebles10.png';
import mueblespng11 from '../img/muebles/muebles11.png';
import mueblespng12 from '../img/muebles/muebles12.png';

import pdfSeparadores from '../pdf/gt/separadores.pdf';
import pdfTumbonas from '../pdf/gt/tumbona.pdf';
import pdfAsientos from '../pdf/gt/asientos.pdf';
import pdfCojines from '../pdf/gt/cojines.pdf';
import pdfMesas from '../pdf/gt/mesas.pdf';

import pdfSeparadoresSlv from '../pdf/slv/separadores.pdf';
import pdfTumbonasSlv from '../pdf/slv/tumbona.pdf';
import pdfAsientosSlv from '../pdf/slv/asientos.pdf';
import pdfCojinesSlv from '../pdf/slv/cojines.pdf';
import pdfMesasSlv from '../pdf/slv/mesas.pdf';
import SliderMuebles from "../Hooks/SliderMuebles";

import Content from "../Hooks/Content";

const Muebles = ({name, loca}) => {
    
    const library = {
        'title': 
        [['muebles',(name !== 'safari') ? muebles1 : mueblespng1],'diseño original', 'materiales resistentes'],
        'images': [
            {
                'img':(name !== 'safari') ? muebles12 : mueblespng12,
                'align': true
            },
            {
                'img':(name !== 'safari') ? muebles7 : mueblespng7,
                'align': true
            },
            {
                'img':(name !== 'safari') ? muebles11 : mueblespng11,
                'align': true
            },
            {
                'img':(name !== 'safari') ? muebles2 : mueblespng2,
                'align': true
            },
            {
                'img':(name !== 'safari') ? muebles8 : mueblespng8,
                'align': true
            },
            {
                'img':(name !== 'safari') ? muebles3 : mueblespng3,
                'align': true
            },
            {
                'img':(name !== 'safari') ? muebles6 : mueblespng6,
                'align': true
            },
            {
                'img':(name !== 'safari') ? muebles9 : mueblespng9,
                'align': true
            },
            {
                'img':(name !== 'safari') ? muebles4 : mueblespng4,
                'align': true
            },
            {
                'img':(name !== 'safari') ? muebles10 : mueblespng10,
                'align': true
            },
            {
                'img':(name !== 'safari') ? muebles5 : mueblespng5,
                'align': true
            }
        ],
        'history': [
            ['Nuestros muebles NARDI conquistan uno de los premios internacionales más importantes que reconoce el diseño de alta calidad.'],
            ['Ligereza, funcionalidad y color. Nuestro mobiliario de exterior y de interior está diseñado para transformar cualquier espacio al aire libre en un lugar cómodo y de convivencia.'],
            ['Amueblar, un verano tras otro, todos nuestros productos son en polipropileno, una resina duradera que resiste a la intemperie y a los ambientes salinos.']
        ],
    }

    const redirectTel = () => {
        window.open("tel:+50223882930", "_blank", "noopener,noreferrer");
    }
    const redirectWhatsapp = () => {
        window.open("https://wa.me/+50240546311","_blank", "noopener,noreferrer");
    }
    return(
        <section className="min-h-[500px] w-full mb-16 overflow-hidden">
            <div id='inicio' className="w-full h-[144px;]"></div>
            <div className="max-w-[1140px] m-auto w-full p-4">
                <div>
                    <h2 className='under-first-leter text-[var(--title-home-color)] text-left tracking-wider text-3xl uppercase'>{library.title[0][0]}</h2>
                    <div className="w-full mt-1 pb-7">
                        <span className="subraydo flex"></span>
                    </div>
                    <div className='min-h-[50px] flex justify-center items-center gap-2 pt-5 mb-20 sx:flex-wrap '>
                        <div className='aspect-w-16 aspect-h-9'>
                            {library.history[0].map((history, index) => {
                                return  <p key={index} className="text-[var(--detail-home-color)] font-medium tracking-widest text-lg mb-2">{history}</p>
                            })}
                        </div>
                        <div className='aspect-w-16 aspect-h-9'>
                            <img className='w-full h-full object-cover relative'
                                src={library.title[0][1]}
                                alt='premio'
                                />
                        </div>
                    </div>
                </div>
                <div>
                    <h2 className='under-first-leter text-[var(--title-home-color)] text-left tracking-wider text-3xl uppercase'>{library.title[1]}</h2>
                    <div className="w-full mt-1 pb-7">
                        <span className="subraydo flex"></span>
                    </div>
                    <div className='min-h-[50px] flex justify-center items-center gap-2 pt-5 mb-20 sx:flex-wrap '>
                        <div className='aspect-w-16 aspect-h-9'>
                            {library.history[1].map((history, index) => {
                                return  <p key={index} className="text-[var(--detail-home-color)] font-medium tracking-widest text-lg mb-2">{history}</p>
                            })}
                        </div>
                    </div>
                </div>
                <div>
                    <h2 className='under-first-leter text-[var(--title-home-color)] text-left tracking-wider text-3xl uppercase'>{library.title[2]}</h2>
                    <div className="w-full mt-1 pb-7">
                        <span className="subraydo flex"></span>
                    </div>
                    <div className='min-h-[50px] flex justify-center items-center gap-2 pt-5 mb-5 sx:flex-wrap '>
                        <div className='aspect-w-16 aspect-h-9'>
                            {library.history[2].map((history, index) => {
                                return  <p key={index} className="text-[var(--detail-home-color)] font-medium tracking-widest text-lg mb-2">{history} <strong>Descarga nuestros catálogos</strong> y conoce más detalles.</p>
                            })}
                        </div>
                    </div>
                </div>
                <div className='w-full flex justify-center items-center mb-5'>
                    <ul className='px-4 text-[var(--primary-information)] text-center uppercase flex justify-center items-center gap-5 flex-wrap'>
                        <NavLink to={loca==='gt'?pdfAsientos:pdfAsientosSlv} rel="noopener noreferrer nofollow" target="_blank" download="sillas_bancas_taburetes.pdf" className="bg-[#003B6D] text-white downButton">
                            <li className='flex justify-center py-2'>
                                <span className='mr-2'>sillas, bancas y taburetes</span> <BsHandIndexThumb size={18}/>
                            </li>
                        </NavLink>
                        <NavLink to={loca==='gt'?pdfMesas:pdfMesasSlv} rel="noopener noreferrer nofollow" target="_blank" download="mesas.pdf" className="bg-[#003B6D] text-white downButton">
                            <li className='flex justify-center py-2'>
                                <span className='mr-2'>mesas</span> <BsHandIndexThumb size={18}/>
                            </li>
                        </NavLink>
                        <NavLink to={loca==='gt'?pdfTumbonas:pdfTumbonasSlv} rel="noopener noreferrer nofollow" target="_blank" download="tumbonas.pdf" className="bg-[#003B6D] text-white downButton">
                            <li className='flex justify-center py-2'>
                                <span className='mr-2'>tumbonas</span> <BsHandIndexThumb size={18}/>
                            </li>
                        </NavLink>
                        <NavLink to={loca==='gt'?pdfSeparadores:pdfSeparadoresSlv} rel="noopener noreferrer nofollow" target="_blank" download="separadores.pdf" className="bg-[#003B6D] text-white downButton">
                            <li className='flex justify-center py-2'>
                                <span className='mr-2'>separadores</span> <BsHandIndexThumb size={18}/>
                            </li>
                        </NavLink>
                        <NavLink to={loca==='gt'?pdfCojines:pdfCojinesSlv} rel="noopener noreferrer nofollow" target="_blank" download="cojines.pdf" className="bg-[#003B6D] text-white downButton">
                            <li className='flex justify-center py-2'>
                                <span className='mr-2'>cojines</span> <BsHandIndexThumb size={18}/>
                            </li>
                        </NavLink>
                    </ul>
                </div>

                <div className='w-full flex justify-center gap-2 mb-5'>
                    <button onClick={redirectTel} className="text-[var(--primary-information)] flex"> <AiFillPhone size={18} className='mr-2'/> <span>Llámanos</span></button>
                    <button onClick={redirectWhatsapp} className="text-[var(--primary-information)] flex"> <FaWhatsapp size={18} className='mr-2'/> <span>Escríbenos</span></button>
                </div>
                
                    {loca==='slv'?
                        library.images.map((img, index) => {
                    return <div key={index} className={`grid ${img.align ? "grid-cols-1" : "grid-cols-2 gap-1" } mb-1`}>
                                {
                                    img.align && (
                                        <div className="aspect-w-1 aspect-h-1">
                                            <img src={img.img} alt="product" className="w-full"/>
                                        </div>
                                    )
                                }
                                {
                                    !img.align && img.img.map((img, index) => {
                                        return <div key={index} className="aspect-w-1 aspect-h-1">
                                                    <img src={img} className="h-full w-full" alt="product" />
                                                </div>
                                    })
                                }
                            </div>
                    }): 
                        <SliderMuebles content={Content}/>
                    }
            </div>
        </section>
    )
}

export default Muebles