import { NavLink } from 'react-router-dom';
import {FaBars} from 'react-icons/fa';
import { useEffect, useState } from 'react';

import logo from '../img/aci-logo.webp';
import logopng from '../img/aci-logo.png';


const Stickyheader = ({name, loca, setLoca}) => {
    const [displayProd, setDisplayProd] = useState(false);
    const [displayLoc, setDisplayLoc] = useState(false);
    const [mobileHead, setMobileHead] = useState(false);
    //const [isLoca, setIsLoca] = useState(false);
    const [head, setHead] = useState(false);


    const handleLocationGT = () => {
        setLoca('gt')
    }

    const handleLocationSLV = () => {
        setLoca('slv')
    }

    const heandleHead = () => {
        setHead(!head)
    }

    const handleShowSubMenu = () => {
        setDisplayProd(true)
    }
    const handleHideSubMenu = () => {
        setDisplayProd(false)
    }
    const handleShowSubMenuMobile = () => {
        setMobileHead(true)
    }
    const handleHideSubMenuMobile = () => {
        setMobileHead(false)
    }

    const handleScroll = () => {
        let locationY = window.scrollY

        if(locationY === 0){
            setDisplayLoc(false)
        }else{
            setDisplayLoc(true)
        }
    }

    useEffect(() => {
      window.addEventListener('scroll',handleScroll)
      return () => {
        window.removeEventListener('scroll',handleScroll)
      }
    }, [])
    
    

    return(
        <header>
            <div className={`secondary-header ${displayLoc ? 'hidden' : ''}`}>
                <div className='flex justify-end items-center px-4'>
                    <ul className='flex px-4'>
                        <li onClick={handleLocationGT}><span className={`gt-location ${loca === 'gt' ? 'select-loca':''}`}><span>Q</span><span>Quetzales</span></span></li>
                        <li onClick={handleLocationSLV}><span className={`slv-location ${loca === 'slv' ? 'select-loca':''}`}><span>$</span><span>Dólares</span></span></li>
                    </ul>
                </div>
            </div>
            <div className='min-h-[107px] py-2 bg-[var(--primary-dark)] shadow w-full flex justify-between items-center z-10 text-[var(--primary-information)] px-4 fixed'>
                <div>
                    <NavLink to='/'>   
                        <figure>
                            <img className='w-60 h-25' src={(name !== 'safari') ? logo : logopng} alt="ACI LOGO"/>
                        </figure>
                    </NavLink>
                    <NavLink className="flex justify-end" to="https://wa.me/50240546311?text=Hola escribo de la p%C3%A1gina web, necesito informaci%C3%B3n de productos" rel="noopener noreferrer nofollow" target="_blank">
                        <span>Contáctanos (502) 4054-6311</span>
                    </NavLink>
                </div>
                <ul className='hidden sm:flex px-4 root-menu'>
                    <li>
                        <NavLink to='/'>Inicio</NavLink>
                    </li>
                    <li>
                        <NavLink to={`/nosotros`}>Nosotros</NavLink>
                    </li>
                    <li onMouseEnter={handleShowSubMenu} onMouseLeave={handleHideSubMenu} className={`${displayProd? "animation-show" : "animate-pulse" } cursor-pointer`}>
                        <span>Productos</span>
                        <div className='hidden z-20 absolute bg-white'>
                            <ul className='flex flex-col px-4 text-[var(--primary-information)]'>
                                <li>
                                    <NavLink to="/muebles">Muebles</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/producto/toldos">Toldos</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/producto/electrodomesticos">Electrodomésticos</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/producto/sombrillas">Sombrillas</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/producto/pergolas">Pérgolas</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/producto/velas">Velas Tensadas</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/producto/telas">Telas Italianas</NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <NavLink to='/contactanos'>Contáctanos</NavLink>
                    </li>
                </ul>
                {/****************** Icono hamburguesa *************************/}
                <div onClick={heandleHead} className='sm:hidden z-20'>
                    <FaBars  size={20} className={head ? 'mr-4 cursor-pointer text-white' :'mr-4 cursor-pointer'}/>
                </div>
                {/****************** Menú mobil *************************/}
                <div className={head ? 'overflow-scroll md:hidden ease-in duration-300 fixed text-gray-300 left-0 top-0 w-full h-screen bg-black/90 px-4 py-7 flex flex-col'
                : 'absolute top-0 h-screen left-[100%] ease-in duration-500 '}>
                    <ul className='h-full w-full text-center pt-12'>
                        <li onClick={heandleHead} className='text-xl py-8'>
                            <NavLink to='/'>Inicio</NavLink>
                        </li>
                        <li onClick={heandleHead} className='text-xl py-8'>
                            <NavLink to='/nosotros'>Nosotros</NavLink>
                        </li>
                        <li onMouseEnter={handleShowSubMenuMobile} onMouseLeave={handleHideSubMenuMobile} className={`${ mobileHead ? "animation-show" : "animate-pulse"} cursor-pointer text-xl py-8`}>
                            <span>Productos</span>
                            <div className='hidden text-gray-300'>
                                <ul className='flex flex-col px-4'>
                                    <li onClick={heandleHead}>
                                        <NavLink to="/muebles">Muebles</NavLink>
                                    </li>
                                    <li onClick={heandleHead}>
                                        <NavLink to="/producto/toldos">Toldos</NavLink>
                                    </li>
                                    <li onClick={heandleHead}>
                                        <NavLink to="/producto/electrodomesticos">Electrodomésticos</NavLink>
                                    </li>
                                    <li onClick={heandleHead}>
                                        <NavLink to="/producto/sombrillas">Sombrillas</NavLink>
                                    </li>
                                    <li onClick={heandleHead}>
                                        <NavLink to="/producto/pergolas">Pérgolas</NavLink>
                                    </li>
                                    <li onClick={heandleHead}>
                                        <NavLink to="/producto/velas">Velas Tensadas</NavLink>
                                    </li>
                                    <li onClick={heandleHead}>
                                        <NavLink to="/producto/telas">Telas Italianas</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li onClick={heandleHead} className='text-xl py-8'>
                            <NavLink to='/contactanos'>Contáctanos</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    )
}

export default Stickyheader