import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const SliderMuebles = ({content}) => {
    return (
        <div>
        {
            Object.keys(content).map((data,nane) => {
                return <div key={nane} className='w-full mb-14'>
                            <h2 className='under-first-leter text-[var(--title-home-color)] text-left tracking-wider text-3xl uppercase'>{content[data].title}</h2>
                            <div className="w-full mt-1 pb-7">
                                <span className="subraydo flex"></span>
                            </div>
                            <Swiper modules={[Navigation, Pagination]} spaceBetween={40} slidesPerView={2} navigation
                                pagination={{ clickable: true }} onSlideChange={() => console.log('slide change')}>
                                    {
                                        content[data].img.map((img,index) =>{
                                            return <SwiperSlide key={index}>
                                                        <div className="aspect-w-1 aspect-h-1">
                                                            <img src={img} className="h-full w-full" alt={nane} />
                                                        </div>
                                                    </SwiperSlide>
                                        })
                                    }
                            </Swiper>
                        </div>
            })
        }
        </div>
        
    );
}

export default SliderMuebles