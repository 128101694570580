import { NavLink } from 'react-router-dom';

import nosotros1 from "../img/nosotros/nosotros1.webp"
import nosotros2 from "../img/nosotros/nosotros2.webp"
import nosotros3 from "../img/nosotros/nosotros3.webp"
import nosotros4 from "../img/nosotros/nosotros4.webp"
import nosotros5 from "../img/nosotros/nosotros5.webp"
import nosotros6 from "../img/nosotros/nosotros6.webp"

import nosotrospng1 from "../img/nosotros/nosotros1.png"
import nosotrospng2 from "../img/nosotros/nosotros2.png"
import nosotrospng3 from "../img/nosotros/nosotros3.png"
import nosotrospng4 from "../img/nosotros/nosotros4.png"
import nosotrospng5 from "../img/nosotros/nosotros5.png"
import nosotrospng6 from "../img/nosotros/nosotros6.png"

const About = ({name,version}) => {
    return (
      <section className='max-w-[1140px] m-auto w-full'>
        <div id='nosotros' className="w-full h-[108px;]"></div>
        <div className='min-h-[550px] p-4 mt-16 mb-12'>
          <h2 className='under-first-leter text-[var(--title-home-color)] text-left tracking-wider text-3xl uppercase'>acerca de nosotros</h2>
          <div className="w-full mt-1">
            <span className="subraydo flex"></span>
          </div>
          <div className='min-h-[50px] flex justify-center items-center gap-2 pt-5 sx:flex-wrap '>
            <div className='aspect-w-16 aspect-h-9'>
              <img className='w-full h-full object-cover relative'
                  src={(name !== 'safari') ? nosotros1 : nosotrospng1}
                  alt='nosotros'
                />
            </div>
            <div className='aspect-w-16 aspect-h-9'>
                <p className="text-[var(--detail-home-color)] font-medium tracking-widest text-lg mb-5">
                  Distribuimos muebles de exterior NARDI, toldos retráctiles, sombrillas, pérgolas y electrodomésticos SMEG, enfocados siempre en alta calidad y diseño italianos.
                </p>
                <p className="text-[var(--detail-home-color)] font-medium tracking-widest text-lg mb-5">
                  Nuestra selectividad en productos de alta calidad y la atención a los detalles, en conjunto con nuestro equipo con disposición de brindar la asesoría pre y post venta necesaria, han hecho que en nuestros más de 15 años de historia nos haya convertido en líderes en nuestro campo en el mercado regional residencial y hotelero.
                </p>
                <p className="text-[var(--detail-home-color)] font-medium tracking-widest text-lg mb-5">
                  Nos dirigimos a clientes del mundo del mobiliario profesional como arquitectos, diseñadores, interioristas, decoradores y abordamos también el sector particular.
                </p>
            </div>
          </div>
        </div>
        <div className='min-h-[100px] p-4 mt-16 mb-12'>
          <h2 className='under-first-leter text-[var(--title-home-color)] text-left tracking-wider text-3xl uppercase'>algunas de nuestras marcas</h2>
          <div className="w-full mt-1">
            <span className="subraydo flex"></span>
          </div>
          <div className='min-h-[50px] flex justify-center items-center gap-5 sx:flex-wrap mt-8'>
                <div className='aspect-w-16 aspect-h-9'>
                  <img className='w-full h-full object-cover relative'
                      src={(name !== 'safari') ? nosotros2 : nosotrospng2}
                      alt='dicorpcortinas'
                  />
                </div>
                <div className='aspect-w-16 aspect-h-9'>
                  <img className='w-full h-full object-cover relative'
                      src={(name !== 'safari') ? nosotros3 : nosotrospng3}
                      alt='telastessuti'
                  />
                </div>
                <div className='aspect-w-16 aspect-h-9'>
                  <img className='w-full h-full object-cover relative'
                      src={(name !== 'safari') ? nosotros4 : nosotrospng4}
                      alt='dicorpcortinas'
                  />
                </div>
                <div className='aspect-w-16 aspect-h-9'>
                  <NavLink to="https://telastessuti.com/" rel="noopener noreferrer nofollow" target="_blank">
                    <img className='w-full h-full object-cover relative scale-75'
                        src={(name !== 'safari') ? nosotros6 : nosotrospng6}
                        alt='telastessuti'
                    />
                  </NavLink>
                </div>
                <div className='aspect-w-16 aspect-h-9'>
                  <img className='w-full h-full object-cover relative'
                      src={(name !== 'safari') ? nosotros5 : nosotrospng5}
                      alt='telastessuti'
                  />
                </div>
            </div>
        </div>
      </section>
    );
  };
  
  export default About;