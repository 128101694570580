import { NavLink } from 'react-router-dom';
import { SiWhatsapp} from 'react-icons/si';

const Whatsapp = () => {

    return(
        <NavLink to="https://wa.me/+50240546311" rel="noopener noreferrer nofollow" target="_blank">
            <SiWhatsapp className="fixed shadow-wpp bottom-20 right-5 z-50 rounded-[8px] animate-pulse bg-[rgb(37,211,102)] text-white p-1" size={60}/>
        </NavLink>
    )
}

export default Whatsapp