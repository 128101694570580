import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import StickyFooter from "./components/StickyFooter";
import StickyHeader from "./components/StickyHeader";
import Whatsapp from "./components/Whatsapp";
import Product from "./components/Product";
import Contact from "./components/Contact"
import Muebles from "./components/Muebles";
import About from "./components/About";
import Main from "./components/Main";
import Bowser from "bowser";
import Aos from "aos";

import "aos/dist/aos.css";

function App() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  let { name,version } = browser.getBrowser();
  const [loca, setLoca] = useState('gt')

  useEffect(() => {
    Aos.init({duration: 2000});
  },[]);
  
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <>
      <StickyHeader mx-4 name={name.toLowerCase()} loca={loca} setLoca={setLoca}/>
      <Wrapper>
        <Routes>
          <Route path="/" element={<Main mx-4 name={name.toLowerCase()} version={version}/>} />
          <Route path="/nosotros" element={<About mx-4 name={name.toLowerCase()} version={version}/>} />
          <Route path="/contactanos" element={<Contact mx-4 name={name.toLowerCase()} version={version} />} />
          <Route path="/muebles" element={<Muebles mx-4 name={name.toLowerCase()} loca={loca}/>} />
          <Route path="/producto/:catProd" element={<Product mx-4 name={name.toLowerCase()} loca={loca} />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Wrapper>
      <Whatsapp/>
      <StickyFooter mx-4/>
    </>
  );
}

export default App;
