import { RxDoubleArrowLeft, RxDoubleArrowRight} from 'react-icons/rx';
import { NavLink } from 'react-router-dom';

import sombrilla1 from '../img/sombrillas/sombrilla1.webp';
import mueble2 from '../img/slider_muebles/slider2.webp';
import mueble3 from '../img/slider_muebles/slider3.webp';
import mueble4 from '../img/slider_muebles/slider4.webp';
import mueble5 from '../img/slider_muebles/slider5.webp';
import mueble6 from '../img/slider_muebles/slider6.webp';
import mueble7 from '../img/slider_muebles/slider7.webp';
import pergola1 from '../img/pergolas/pergola.webp';
import electro1 from '../img/smeg/electro1.webp';
import toldo1 from '../img/toldos/toldo1.webp';
import toldo2 from '../img/toldos/toldo2.webp';

import scolaro from '../img/sombrillas/scolaro.jpg';
import sombrillapng1 from '../img/sombrillas/sombrilla1.png';
import mueblejpg1 from '../img/slider_muebles/slider1.jpg';
import mueblepng2 from '../img/slider_muebles/slider2.png';
import mueblepng3 from '../img/slider_muebles/slider3.png';
import mueblepng4 from '../img/slider_muebles/slider4.png';
import mueblepng5 from '../img/slider_muebles/slider5.png';
import mueblepng6 from '../img/slider_muebles/slider6.png';
import mueblepng7 from '../img/slider_muebles/slider7.png';
import pergolapng1 from '../img/pergolas/pergola.gif';
import electropng1 from '../img/smeg/electro1.png';
import toldopng1 from '../img/toldos/toldo1.png';
import toldopng2 from '../img/toldos/toldo2.png';

const Categories = ({name, version}) => {
    return (
      <section className='w-full overflow-hidden'>
        <div id='productos' className='min-h-[375px] p-4 my-4 mx-14 sx:mt-0'>
          {/******************* Muebles ************************/}
          <div className='min-h-[450px] relative flex justify-center items-center gap-2 my-24 dm:flex-wrap'>
            <div id="carouselExampleSlidesOnly" className="carousel slide relative" data-bs-ride="carousel">
              <div className="carousel-inner relative w-full overflow-hidden">
                <div className="carousel-item active relative float-left w-[200px] h-[200px] md:w-[947px] md:h-[735px]">
                  <div className='aspect-w-16 aspect-h-9'>
                    <img src={mueblejpg1} className="block w-full h-full" alt="muebles exteriores" />
                  </div>
                </div>
                <div className="carousel-item relative float-left w-[200px] h-[200px] md:w-[947px] md:h-[735px]">
                <div className='aspect-w-16 aspect-h-9'>
                  <img src={(name !== 'safari') ? mueble2 : mueblepng2} className="block w-full h-full" alt="muebles exteriores"/>
                </div>
                </div>
                <div className="carousel-item relative float-left w-[200px] h-[200px] md:w-[947px] md:h-[735px]">
                <div className='aspect-w-16 aspect-h-9'>
                  <img src={(name !== 'safari') ? mueble3 : mueblepng3} className="block w-full h-full" alt="muebles exteriores"/>
                </div>
                </div>
                <div className="carousel-item relative float-left w-[200px] h-[200px] md:w-[947px] md:h-[735px]">
                <div className='aspect-w-16 aspect-h-9'>
                  <img src={(name !== 'safari') ? mueble4 : mueblepng4} className="block w-full h-full" alt="muebles exteriores"/>
                </div>
                </div>
                <div className="carousel-item relative float-left w-[200px] h-[200px] md:w-[947px] md:h-[735px]">
                <div className='aspect-w-16 aspect-h-9'>
                  < img src={(name !== 'safari') ? mueble5 : mueblepng5} className="block w-full h-full" alt="muebles exteriores"/>
                </div>
                </div>
                <div className="carousel-item relative float-left w-[200px] h-[200px] md:w-[947px] md:h-[735px]">
                <div className='aspect-w-16 aspect-h-9'>
                  < img src={(name !== 'safari') ? mueble6 : mueblepng6} className="block w-full h-full" alt="muebles exteriores"/>
                </div>
                </div>
                <div className="carousel-item relative float-left w-[200px] h-[200px] md:w-[947px] md:h-[735px]">
                <div className='aspect-w-16 aspect-h-9'>
                  < img src={(name !== 'safari') ? mueble7 : mueblepng7} className="block w-full h-full" alt="muebles exteriores"/>
                </div>
                </div>
              </div>
            </div>
            <div>
              <h2 className='text-[var(--title-home-color)] text-right font-medium tracking-wider text-3xl md:text-7xl'>muebles de exterior</h2>
              <p className='text-[var(--detail-home-color)] text-right font-medium tracking-widest text-lg my-5'>
                el diseño, el relax al aire libre, las ganas de estar juntos y de disfrutar. Línea residencial y hotelera de muebles italianos NARDI.
              </p>
              <div>
                <NavLink to="/muebles">
                  <span className='flex justify-end items-center gap-3 text-[var(--detail-home-color)] uppercase tracking-wider hover:text-red-500 cursor-pointer w-36 absolute right-0'>
                    <span>
                      < RxDoubleArrowLeft/>
                    </span>
                    <span> | </span>
                    <span>descubre</span>
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          {/******************* Sombrillas ************************/}
          <div className='min-h-[450px] relative overflow-hidden'>
            <div className='flex justify-center items-center gap-2 my-24 dm:flex-wrap'>
              <div>
                <h2 className='text-[var(--title-home-color)] text-left font-medium tracking-wider text-2xl md:text-7xl'>sombrillas</h2>
                <p className='text-[var(--detail-home-color)] text-left font-medium tracking-widest text-lg my-5'>
                  líderes en la distribución de sombrillas de jardín para hogares y hotelería desde hace 45 años.
                </p>
                <div className='w-full flex justify-between flex-wrap'>
                  <NavLink to="/producto/sombrillas">
                    <span className='flex justify-start items-center gap-3 text-[var(--detail-home-color)] uppercase tracking-wider hover:text-red-500'>
                      <span>descubre</span>
                      <span> | </span>
                      <span>
                        < RxDoubleArrowRight/>
                      </span>
                    </span>
                  </NavLink>
                  <img className='w-[50%] h-full object-cover relative'
                          src={scolaro}
                          alt='sombrillas_scolaro'
                    />
                </div>
              </div>
              <div className='aspect-w-16 aspect-h-9'>
                <img className='w-full h-full object-cover relative'
                    src={(name !== 'safari') ? sombrilla1 : sombrillapng1}
                    alt='sombrillas'
                  />
              </div>
            </div>
          </div>
          {/******************* Toldos ************************/}
          <div className='min-h-[450px]  relative'>
            <div className='flex justify-center items-center gap-2 my-24 dm:flex-wrap'>
              <div className='aspect-w-16 aspect-h-9 animate-toldo'>
                <img className='w-full h-full object-cover relative -scale-x-100'
                    src={(name !== 'safari') ? toldo1 : toldopng1}
                    alt='toldos'
                  />
                  <img className='hidden w-full h-full object-cover relative -scale-x-100'
                    src={(name !== 'safari') ? toldo2 : toldopng2}
                    alt='toldos'
                  />
              </div>
              <div>
                <h2 className='text-[var(--title-home-color)] text-left font-medium tracking-wider text-3xl md:text-7xl'>toldos retráctiles</h2>
                <p className='text-[var(--detail-home-color)] text-left font-medium tracking-widest text-lg my-5'>
                  sombras a otro nivel que solo el espíritu italiano puede ofrecer, estructura de aluminio, tejidos outdoor para uso residencial y hotelero.
                </p>
                <div>
                  <NavLink to="/producto/toldos">
                    <span className='flex justify-end items-center gap-3 text-[var(--detail-home-color)] uppercase tracking-wider hover:text-red-500'>
                    <span>
                        < RxDoubleArrowLeft/>
                      </span>
                      <span> | </span>
                      <span>descubre</span>
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          {/******************* Pergolas ************************/}
          <div className='min-h-[450px] relative overflow-hidden'>
            <div className='flex justify-center items-center gap-2 my-24 dm:flex-wrap'>
              <div>
                <h2 className='text-[var(--title-home-color)] text-left font-medium tracking-wider text-2xl md:text-7xl'>pérgolas louver</h2>
                <p className='text-[var(--detail-home-color)] text-left font-medium tracking-widest text-lg my-5'>
                  funcionalidad y estilo fabricada 100% de aluminio con paletas abatibles manualmente para apertura y cierre, totalmente impermeable cuando está cerrada.
                </p>
                <div>
                  <NavLink to="/producto/pergolas">
                    <span className='flex justify-start items-center gap-3 text-[var(--detail-home-color)] uppercase tracking-wider hover:text-red-500'>
                      <span>descubre</span>
                      <span> | </span>
                      <span>
                        < RxDoubleArrowRight/>
                      </span>
                    </span>
                  </NavLink>
                </div>
              </div>
              <div className='aspect-w-16 aspect-h-9'>
                <img className='w-full h-full object-cover relative'
                    src={(name !== 'safari') ? pergola1 : pergolapng1}
                    alt='pergolas'
                  />
              </div>
            </div>
          </div>
          {/******************* Smeg ************************/}
          <div className='min-h-[450px] relative'>
            <div className='flex justify-center items-center gap-2 my-24 dm:flex-wrap'>
              <div className='aspect-w-16 aspect-h-9'>
                <img className='w-full h-full object-cover relative'
                    src={(name !== 'safari') ? electro1 : electropng1}
                    alt='SMEG'
                  />
              </div>
              <div>
                <h2 className='text-[var(--title-home-color)] text-right font-medium tracking-wider text-2xl md:text-7xl'>SMEG appliances</h2>
                <p className='text-[var(--detail-home-color)] text-right font-medium tracking-widest text-lg my-5'>
                  color, diseño y tecnología, innovación italiana para todos los entornos cotidianos desde 1948.
                </p>
                <div>
                  <NavLink to="/producto/electrodomesticos">
                    <span className='flex justify-end items-center gap-3 text-[var(--detail-home-color)] uppercase tracking-wider hover:text-red-500'>
                      <span>
                        < RxDoubleArrowLeft/>
                      </span>
                      <span> | </span>
                      <span>descubre</span>
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    );
  };
  
  export default Categories;