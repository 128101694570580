import { NavLink } from "react-router-dom";
import {AiFillPhone} from 'react-icons/ai';
import {FaWhatsapp} from 'react-icons/fa';
import {BsHandIndexThumb} from 'react-icons/bs';

const Smeg = ({library, pdfSmeg}) => {
    const redirectTel = () => {
        window.open("tel:+50223882930", "_blank", "noopener,noreferrer");
    }
    const redirectWhatsapp = () => {
        window.open("https://wa.me/+50240546311","_blank", "noopener,noreferrer");
    }
    return(
        <section className="min-h-[810px] dm:min-h-[1000px] w-full mb-16 overflow-hidden">
            <div id='inicio' className="w-full h-[144px;]"></div>
            <div className="max-w-[1140px] m-auto w-full p-4">
                <h2 className='under-first-leter text-[var(--title-home-color)] text-left tracking-wider text-3xl dm:text-xl uppercase'>{library.title[0]}</h2>
                <div className="w-full mt-1 pb-7">
                    <span className="subraydo flex"></span>
                </div>
                <div className='min-h-[50px] flex justify-center items-center gap-2 pt-5 mb-2 sx:flex-wrap '>
                    <div className='aspect-w-16 aspect-h-9'>
                        <p className="text-[var(--detail-home-color)] font-medium tracking-widest text-lg mb-5">
                            {library.history[0]}
                        </p>
                        <p className="text-[var(--detail-home-color)] font-medium tracking-widest text-lg mb-5">
                            {library.history[1]}
                        </p>
                        <p className="text-[var(--detail-home-color)] font-medium tracking-widest text-lg mb-5">
                            {library.history[2]}
                        </p>
                        <p className="text-[var(--detail-home-color)] font-medium tracking-widest text-lg mb-3">
                            ... Dale clic a <strong>Descubre</strong> y descarga nuestro catálogo.
                        </p>
                    </div>
                    <div className='aspect-w-16 aspect-h-9'>
                        <img className='w-full h-full object-cover relative'
                            src={library.title[1].img}
                            alt='smeg'
                            />
                    </div>
                </div>
                <div className='grid grid-cols-1 pb-5'>
                    <NavLink to={pdfSmeg} rel="noopener noreferrer nofollow" target="_blank" download="smeg.pdf" className="flex justify-center px-5 py-3 max-w-[10rem] text-center bg-[#003B6D] text-white downButton">
                        <span className='mr-2'>Descubre</span> <BsHandIndexThumb size={18}/>
                    </NavLink>
                </div>
                <div className='w-full flex justify-center gap-2 mb-5'>
                    <button onClick={redirectTel} className="text-[var(--primary-information)] flex"> <AiFillPhone size={18} className='mr-2'/> <span>Llámanos</span></button>
                    <button onClick={redirectWhatsapp} className="text-[var(--primary-information)] flex"> <FaWhatsapp size={18} className='mr-2'/> <span>Escríbenos</span></button>
                </div>
            </div>
        </section>
    )
}

export default Smeg