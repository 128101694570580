import {AiOutlineClose} from 'react-icons/ai'

const ListImagesUpload = ({uploadImages, setUploadFiles}) => {

    const handleRemoveImage = (index) => (e) =>{
        const images = [...uploadImages]
        images.splice(index,1)
        setUploadFiles(images)
    }

    return(
        <div className="flex justify-center items-center gap-1 sx:flex-wrap">
            {
                uploadImages.map((img,i) => (
                    <div key={i} className="relative">
                        <div className="aspect-w-16 aspect-h-9">
                            <img src={URL.createObjectURL(img)} alt=''/>
                        </div>
                        <span onClick={handleRemoveImage(i)} className='cursor-pointer absolute top-0 right-0 text-white bg-[var(--primary-information)] rounded-full'>
                            <AiOutlineClose/>
                        </span>
                    </div>
                ))
            }
        </div>
    )
}

export default ListImagesUpload