import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from 'react';

import Sombrillas from "./Sombrillas";
import Pergolas from "./Pergolas";
import Toldos from "./Toldos";
import Velas from "./Velas";
import Telas from "./Telas";
import Smeg from "./Smeg";
/******************* Sombrillas ************************/

import sombrilla2 from '../img/sombrillas/sombrilla2.webp';
import sombrilla3 from '../img/sombrillas/sombrilla3.webp';
import sombrilla4 from '../img/sombrillas/sombrilla4.webp';
import sombrilla5 from '../img/sombrillas/sombrilla5.webp';
import sombrilla6 from '../img/sombrillas/sombrilla6.webp';
import sombrilla7 from '../img/sombrillas/sombrilla7.webp';
import sombrillaspdf from '../pdf/gt/sombrillas.pdf'
import sombrillaspdfSlv from '../pdf/slv/sombrillas.pdf'

import toldo3 from '../img/toldos/toldo3.webp';
import toldo4 from '../img/toldos/toldo4.webp';
import toldo5 from '../img/toldos/toldo5.webp';
import toldo6 from '../img/toldos/toldo6.webp';
import toldo7 from '../img/toldos/toldo7.webp';

import pergola1 from '../img/pergolas/pergola1.webp';
import pergola2 from '../img/pergolas/pergola2.webp';
import pergola3 from '../img/pergolas/pergola3.webp';
import pergola5 from '../img/pergolas/pergola5.webp';
import pergola6 from '../img/pergolas/pergola6.webp';
import pergola7 from '../img/pergolas/pergola7.webp';
import pdfPergola from '../pdf/gt/pergola.pdf';
import pdfPergolaSlv from '../pdf/slv/pergola.pdf';

import electrodomestico from '../img/smeg/electro2.webp';
import pdfSmeg from '../pdf/gt/smeg.pdf';
import pdfSmegSlv from '../pdf/slv/smeg.pdf';

import vela1 from '../img/velas/vela1.svg';
import vela2 from '../img/velas/vela2.webp';
import vela3 from '../img/velas/vela3.webp';
import vela4 from '../img/velas/vela4.webp';
import vela5 from '../img/velas/vela5.webp';
import vela6 from '../img/velas/vela6.webp';
import vela7 from '../img/velas/vela7.webp';
import vela8 from '../img/velas/vela8.webp';
import vela9 from '../img/velas/vela9.webp';
import vela10 from '../img/velas/vela10.webp';

import sombrillapng2 from '../img/sombrillas/sombrilla2.png';
import sombrillapng3 from '../img/sombrillas/sombrilla3.png';
import sombrillapng4 from '../img/sombrillas/sombrilla4.png';
import sombrillapng5 from '../img/sombrillas/sombrilla5.png';
import sombrillapng6 from '../img/sombrillas/sombrilla6.png';
import sombrillapng7 from '../img/sombrillas/sombrilla7.png';

import toldopng3 from '../img/toldos/toldo3.png';
import toldopng4 from '../img/toldos/toldo4.png';
import toldopng5 from '../img/toldos/toldo5.png';
import toldopng6 from '../img/toldos/toldo6.png';
import toldopng7 from '../img/toldos/toldo7.png';

import pergolapng1 from '../img/pergolas/pergola1.png';
import pergolapng2 from '../img/pergolas/pergola2.png';
import pergolapng3 from '../img/pergolas/pergola3.png';
import pergolapng5 from '../img/pergolas/pergola5.png';
import pergolapng6 from '../img/pergolas/pergola6.png';
import pergolapng7 from '../img/pergolas/pergola7.png';

import velapng2 from '../img/velas/vela2.png';
import velapng3 from '../img/velas/vela3.png';
import velapng4 from '../img/velas/vela4.png';
import velapng5 from '../img/velas/vela5.png';
import velapng6 from '../img/velas/vela6.png';
import velapng7 from '../img/velas/vela7.png';
import velapng8 from '../img/velas/vela8.png';
import velapng9 from '../img/velas/vela9.png';
import velapng10 from '../img/velas/vela10.png';

import electrodomesticopng from '../img/smeg/electro2.png';

import telas1 from '../img/telas/tessuti.jpg';
import telas2 from '../img/telas/telas1.jpg';
import telas3 from '../img/telas/telas2.jpg';
import telas4 from '../img/telas/telas3.jpg';
import telas6 from '../img/telas/telas5.jpg';
import telas8 from '../img/telas/telas6.jpg';
import telas9 from '../img/telas/telas8.jpg';
import telas10 from '../img/telas/telas9.jpg';
import telas12 from '../img/telas/telas10.jpg';
import telas13 from '../img/telas/telas12.jpg';
import telas14 from '../img/telas/telas13.jpg';
import telas15 from '../img/telas/telas14.jpg';
import telas16 from '../img/telas/telas15.jpg';



const Product = ({name, loca}) => {
    const { catProd } = useParams();
    const navigate = useNavigate();
    
    const library = {
        'sombrillas': {
            'title': 'Sombrillas',
            'images': [
                {
                    'img': (name !== 'safari') ? sombrilla2 : sombrillapng2,
                    'align': true
                },
                {
                    'img': (name !== 'safari') ? sombrilla4 : sombrillapng4,
                    'align': true
                },
                {
                    'img': [(name !== 'safari') ? sombrilla3 : sombrillapng3,(name !== 'safari') ? sombrilla5 : sombrillapng5],
                    'align': false
                },
                {
                    'img': (name !== 'safari') ? sombrilla6 : sombrillapng6,
                    'align': true
                },
                {
                    'img': (name !== 'safari') ? sombrilla7 : sombrillapng7,
                    'align': true
                }
            ],
            'history': [
                'Parasoles voladizos con sistema de cierre retráctil de distintos tamaños con una estructura más reforzada, sistema de base y giratorio actualizado y mejorado.',
                'Modelos producidos por Scolaro desde hace más de 20 años oportunamente renovados. Miles de piezas vendidas en todo el mundo, de las cuales muchas siguen en uso, son la prueba de una óptima planificación y que este artículo resume tradición y experiencia en el ramo. Soportes Verticales de aluminio provisto de un acabado especial en madera para un Diseño inalterable.'
            ],
        },
        'toldos': {
            'title': 'Nuestro exclusivo diseño de toldos',
            'images': [
                {
                    'img': (name !== 'safari') ? toldo3 : toldopng3,
                    'align': true
                },
                {
                    'img': (name !== 'safari') ? toldo4 : toldopng4,
                    'align': true
                },
                {
                    'img': (name !== 'safari') ? toldo5 : toldopng5,
                    'align': true
                },
                {
                    'img': (name !== 'safari') ? toldo6 : toldopng6,
                    'align': true
                },
                {
                    'img': (name !== 'safari') ? toldo7 : toldopng7,
                    'align': true
                }
            ],
            'history': [
                'Una línea de productos funcionales, sofisticados y sencillamente bellos, capaces de satisfacer todas las necesidades y adaptarse a cualquier contexto arquitectónico.',
                'Un toldo clásico, pero al mismo tiempo nuevo en diseño y funcional en tecnología.',
                'Los componentes de nuestros toldos han sido diseñados a la perfección, asegurando una funcionalidad nunca antes vista en un toldo monobloc.',
                'Nuestros toldos coinciden con nuestro deseo de romper los límites de diseño, creando algo nuevo. Contamos con una amplia variedad de tipos y telas italianas; texturas, colores, patterns y diseños. Tejidos refinados y Tejidos Reciclados.'
            ],
        },
        'pergolas':{
            'title': 
            [
                'Pérgolas',
                {
                    'img': (name !== 'safari') ? pergola3 : pergolapng3,
                    'align': true
                },
            ],
            'images': [
                {
                    'img': [(name !== 'safari') ? pergola1 : pergolapng1,(name !== 'safari') ? pergola2 : pergolapng2],
                    'align': false
                },
                {
                    'img': [(name !== 'safari') ? pergola5 : pergolapng5,(name !== 'safari') ? pergola6 : pergolapng6],
                    'align': false
                },
                {
                    'img': (name !== 'safari') ? pergola7 : pergolapng7,
                    'align': true
                }
            ],
            'history': [
                'Sol cuando quieras, sombra cuando necesites.',
                'Hermosa pérgola elaborada al 100% con aleación de aluminio y mecanismo manual de apertura y cierre.',
                'Contamos con medidas disponibles desde 2x4 hasta 4x4.'
            ],
        },
        'electrodomesticos':{
            'title': 
            [
                'Electrodomésticos',
                {
                    'img': (name !== 'safari') ? electrodomestico : electrodomesticopng,
                    'align': true
                },
            ],
            'history': [
                'Estilo para Smeg es la forma en que un producto se comunica con su entorno y permite a los usuarios interactuar con él, estableciendo así una relación con el usuario que va más allá de las preocupaciones prácticas.',
                'Con esta idea, Smeg ha llevado a cabo una minuciosa investigación estética y estilística.',
                'Conoce nuestra línea Smeg Retro Style disponible sólo en ACI.'
            ],
        },
        'velas':{
            'title': 
            [
                'Velas Tensadas',
                {
                    'img': vela1,
                    'align': true
                },
            ],
            'images': [
                {
                    'img': (name !== 'safari') ? vela2 : velapng2,
                    'align': true
                },
                {
                    'img': (name !== 'safari') ? vela3 : velapng3,
                    'align': true
                },
                {
                    'img': [(name !== 'safari') ? vela4 : velapng4,(name !== 'safari') ? vela5 : velapng5],
                    'align': false
                },
                {
                    'img': (name !== 'safari') ? vela6 : velapng6,
                    'align': true
                },
                {
                    'img': (name !== 'safari') ? vela7 : velapng7,
                    'align': true
                },
                {
                    'img': (name !== 'safari') ? vela8 : velapng8,
                    'align': true
                },
                {
                    'img': (name !== 'safari') ? vela9 : velapng9,
                    'align': true
                },
                {
                    'img': (name !== 'safari') ? vela10 : velapng10,
                    'align': true
                },
            ],
            'history': [
                'Es un sistema ideal para zonas donde no se dispone de techos, paredes o superficies de apoyo, siendo especialmente adecuado para cubrir jardines y terrazas de los sectores de hotelería, restaurantes y casas de playa.',
                'Se ancla a cuatro o tres esquinas existentes, sobre estructura fabricada por nosotros o sobre estructura existente.',
                `Los precios de nuestras velas tensadas van desde ${loca==="gt"?'Q.390.00':'USD.60.00'} el metro cuadrado, escríbenos para cotizar la tuya.`
            ],
        },
        'telas':{
            'title': 
            [
                'Telas Italianas',
                {
                    'img': telas1,
                    'align': true
                }
            ],
            'images': [
                {
                    'img': telas2,
                    'align': true
                },
                {
                    'img': [telas3,telas4],
                    'align': false
                },
                {
                    'img': telas6,
                    'align': true
                },
                {
                    'img': telas8,
                    'align': true
                },
                {
                    'img': telas9,
                    'align': true
                },
                {
                    'img': telas10,
                    'align': true
                },
                {
                    'img': telas12,
                    'align': true
                },
                {
                    'img': telas13,
                    'align': true
                },
                {
                    'img': telas14,
                    'align': true
                },
                {
                    'img': telas15,
                    'align': true
                },
                {
                    'img': telas16,
                    'align': true
                },
            ],
            'history': [
                'Telas italianas aptas para cualquier aplicación interior y exterior,para aplicaciones en cojines, mobiliario, sombrillas, toldos y marina.',
                'Tejidos Irisun, combina texturas, colores, patrones y diseños de alto nivel, somos amigos del medio ambiente, contamos una colección completa de tejidos reciclados Raytent® con certificados OEKO-TEX.'
            ],
        },
    }

    useEffect(() => {
        if (catProd !== 'sombrillas' && catProd !== 'toldos' && catProd !== 'pergolas'
        && catProd !== 'electrodomesticos' && catProd !== 'velas' && catProd !== 'telas'){
            return navigate("/");
        }
      },[catProd, navigate]);
    
    try {
        if (catProd === 'pergolas'){
            return(
                <Pergolas library={library[catProd]} pdfPergola={loca==='gt'?pdfPergola:pdfPergolaSlv}/>
            )
        }else if(catProd === 'velas'){
            return(
                <Velas library={library[catProd]}/>
            )
        }else if(catProd === 'electrodomesticos'){
            return(
                <Smeg library={library[catProd]} pdfSmeg={loca==='gt'?pdfSmeg:pdfSmegSlv}/>
            )
        }else if(catProd === 'sombrillas'){
            return(
                <Sombrillas library={library[catProd]} sombrillaspdf={loca==='gt'?sombrillaspdf:sombrillaspdfSlv}/>
            )
        }else if(catProd === 'telas'){
            return(
                <Telas library={library[catProd]} loca={loca}/>
            )
        }else{
            return(
                <Toldos library={library[catProd]} />
            )
        }
    } catch (error) {
        
    }
}

export default Product