import cojines1 from '../img/muebles/Q/Cojines/cojines1.jpg';
import cojines2 from '../img/muebles/Q/Cojines/cojines2.jpg';
import cojines3 from '../img/muebles/Q/Cojines/cojines3.jpg';
import cojines4 from '../img/muebles/Q/Cojines/cojines4.jpg';
import cojines5 from '../img/muebles/Q/Cojines/cojines5.jpg';
import cojines6 from '../img/muebles/Q/Cojines/cojines6.jpg';

import comedor1 from '../img/muebles/Q/Comedores/comedor1.jpg';
import comedor2 from '../img/muebles/Q/Comedores/comedor2.jpg';
import comedor3 from '../img/muebles/Q/Comedores/comedor3.jpg';
import comedor4 from '../img/muebles/Q/Comedores/comedor4.jpg';
import comedor5 from '../img/muebles/Q/Comedores/comedor5.jpg';
import comedor6 from '../img/muebles/Q/Comedores/comedor6.jpg';

import mesa1 from '../img/muebles/Q/Mesas/mesa1.jpg';
import mesa2 from '../img/muebles/Q/Mesas/mesa2.jpg';
import mesa3 from '../img/muebles/Q/Mesas/mesa3.jpg';
import mesa4 from '../img/muebles/Q/Mesas/mesa4.jpg';
import mesa5 from '../img/muebles/Q/Mesas/mesa5.jpg';

import sala1 from '../img/muebles/Q/Salas/sala1.jpg';
import sala2 from '../img/muebles/Q/Salas/sala2.jpg';
import sala3 from '../img/muebles/Q/Salas/sala3.jpg';
import sala4 from '../img/muebles/Q/Salas/sala4.jpg';
import sala5 from '../img/muebles/Q/Salas/sala5.jpg';
import sala6 from '../img/muebles/Q/Salas/sala6.jpg';

import separador1 from '../img/muebles/Q/Separador/separador1.jpg';

import silla1 from '../img/muebles/Q/Sillas/silla1.jpg';
import silla2 from '../img/muebles/Q/Sillas/silla2.jpg';
import silla3 from '../img/muebles/Q/Sillas/silla3.jpg';
import silla4 from '../img/muebles/Q/Sillas/silla4.jpg';
import silla5 from '../img/muebles/Q/Sillas/silla5.jpg';

import sillon1 from '../img/muebles/Q/Sillones/sillon1.jpg';
import sillon2 from '../img/muebles/Q/Sillones/sillon2.jpg';
import sillon3 from '../img/muebles/Q/Sillones/sillon3.jpg';
import sillon4 from '../img/muebles/Q/Sillones/sillon4.jpg';
import sillon5 from '../img/muebles/Q/Sillones/sillon5.jpg';
import sillon6 from '../img/muebles/Q/Sillones/sillon6.jpg';

import taburete1 from '../img/muebles/Q/Taburetes/taburete1.jpg';
import taburete2 from '../img/muebles/Q/Taburetes/taburete2.jpg';
import taburete3 from '../img/muebles/Q/Taburetes/taburete3.jpg';
import taburete4 from '../img/muebles/Q/Taburetes/taburete4.jpg';
import taburete5 from '../img/muebles/Q/Taburetes/taburete5.jpg';
import taburete6 from '../img/muebles/Q/Taburetes/taburete6.jpg';

import tumbona1 from '../img/muebles/Q/Tumbonas/tumbona1.jpg';
import tumbona2 from '../img/muebles/Q/Tumbonas/tumbona2.jpg';
import tumbona3 from '../img/muebles/Q/Tumbonas/tumbona3.jpg';
import tumbona4 from '../img/muebles/Q/Tumbonas/tumbona4.jpg';

const content =  {
    'sillas': {
        'title': 'Sillas',
        'img': [silla1,silla2,silla3,silla4,silla5]
    },
    'sillones': {
        'title': 'Sillones',
        'img': [sillon1,sillon2,sillon3,sillon4,sillon5,sillon6]
    },
    'comedores': {
        'title': 'Comedores',
        'img': [comedor1,comedor2,comedor3,comedor4,comedor5,comedor6]
    },
    'Mesas': {
        'title': 'Mesas',
        'img': [mesa1,mesa2,mesa3,mesa4,mesa5]
    },
    'taburetes': {
        'title': 'Taburetes',
        'img': [taburete1,taburete2,taburete3,taburete4,taburete5,taburete6]
    },
    'tumbonas': {
        'title': 'Tumbonas',
        'img': [tumbona1,tumbona2,tumbona3,tumbona4]
    },
    'salas': {
        'title': 'Salas',
        'img': [sala1,sala2,sala3,sala4,sala5,sala6]
    },
    'separador': {
        'title': 'Separador',
        'img': [separador1]
    },
    'cojines': {
        'title': 'Cojines',
        'img': [cojines1,cojines2,cojines3,cojines4,cojines5,cojines6]
    }
}

export default content;