import {FaFacebookF, FaWhatsapp, FaMapMarkerAlt, FaInstagram} from 'react-icons/fa';
import {AiFillPhone, AiOutlineCopyright} from 'react-icons/ai';
import { NavLink } from 'react-router-dom';

const StickyFooter = () => {

    return(
        <footer>
            <div className='flex justify-between items-center px-4 pb-1 pt-5'>
                <div className='flex items-center'>
                    <FaMapMarkerAlt size={18} className='text-[var(--primary-information)] mr-2'/>
                    <span className='text-xs sx:text-sx text-[var(--primary-information)] mr-2'>9Av. 2-94 Zona 2 de Mixco Colonia Alvarado.</span>
                    <span className='text-xs sx:text-sx text-[var(--primary-information)] mr-2'> | </span>
                    <AiFillPhone size={18} className='text-[var(--primary-information)] mr-2'/>
                    <span className='text-[var(--primary-information)]  text-xs sx:text-sx mr-2'>
                        <a href='tel:+50223882930'>(+502) 2388-2930</a>
                    </span>
                    <span className='text-xs sx:text-sx text-[var(--primary-information)] mr-2'> | </span>
                    <FaWhatsapp size={18} className='mr-2'/>
                    <span className='text-[var(--primary-information)]  text-xs sx:text-sx mr-2'>
                        <NavLink to="https://wa.me/+50240546311" rel="noopener noreferrer nofollow" target="_blank">(+502) 4054-6311</NavLink>
                    </span>
                </div>
                <div className='flex items-end'>
                    <NavLink to="https://www.instagram.com/ACITOLDOSYSOMBRAS/" rel="noopener noreferrer nofollow" target="_blank">
                        <FaInstagram size={18} className='mr-2'/>
                    </NavLink>
                    <NavLink to="https://www.facebook.com/ACIToldosSombrasyMuebles" rel="noopener noreferrer nofollow" target="_blank">
                        <FaFacebookF size={18} className='mr-2'/>
                    </NavLink>
                    <NavLink to="https://wa.me/+50240546311" rel="noopener noreferrer nofollow" target="_blank">
                        <FaWhatsapp size={18} className='mr-2'/>
                    </NavLink>
                </div>
            </div>
            <div className='flex justify-center items-center px-4 pt-2 pb-5'>
                <div className='flex items-center'>
                    <AiOutlineCopyright size={15} className='text-[var(--primary-information)] mr-1'/>
                    <span className='text-xs sx:text-sx text-[var(--primary-information)] mr-2'> COPYRIGHT 2023 </span>
                    <span className='text-xs sx:text-sx text-[var(--primary-information)] mr-2'> | </span>
                    <span className='text-xs sx:text-sx text-[var(--primary-information)] mr-2'>
                        <NavLink to="/">ACI DECORACION</NavLink>
                    </span>
                    <span className='text-xs sx:text-sx text-[var(--primary-information)] mr-2'> | </span>
                    <span className='text-xs sx:text-sx text-[var(--primary-information)]'>
                        <NavLink to="https://www.yetiadvisers.com/" rel="noopener noreferrer nofollow" target="_blank">POWERED BY YETI ADVISERS</NavLink>
                    </span>
                </div>
            </div>
        </footer>
    )
}

export default StickyFooter