import Categories from "./Categorias"
import Slider from "./Slider"

const Main = ({name, version}) => {

    return(
        <main className="min-h-[500px] w-full">
            <Slider name={name} version={version}/>
            <Categories name={name} version={version}/>
        </main>
    )
}

export default Main