import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ListImagesUpload from './ListImagesUpload';

import contact from '../img/nosotros/nosotros1.webp';

import contactanosjpg1 from "../img/contactanos/contacto1.png";
import contactpng from '../img/nosotros/nosotros1.png';

const Contact = ({name, version}) => {
  const [inputValueLastName, setInputValueLastName] = useState('');
  const [inputValuePhone, setInputValuePhone] = useState('');
  const [inputValueName, setInputValueName] = useState('');
  const [inputValueMail, setInputValueMail] = useState('');
  const [inputValueDesc, setInputValueDesc] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [allValues, setAllValues] = useState(false);
  const [sentOk, setSentOk] = useState(false);
  const inputFile = useRef();


  const handleInputChangeName = (e) => {
    setInputValueName(e.target.value);
  }
  const handleInputChangeLastName = (e) => {
    setInputValueLastName(e.target.value);
  }
  const handleInputChangeMail = (e) => {
    setInputValueMail(e.target.value);
  }
  const handleInputChangePhone = (e) => {
    setInputValuePhone(e.target.value);
  }
  const handleInputChangeDesc = (e) => {
    setInputValueDesc(e.target.value);
  }

  const handleSendMail = () => {
    window.Email.send({
      SecureToken: '4b5b3a57-9bd9-4df3-a9f4-0707936f92d6',
      From : 'paginasyetiadvisers@gmail.com',
      To : 'mariana.murga@gmail.com',
      Subject : "Contacto pagina ACI",
      Body : `<div>
                <h2>ACI - DECORACION</h2>
                <p>¡La persona ${inputValueName} ${inputValueLastName} te ha contactado a través de la página web!</p>
                <p>Contacto: </p>
                <table>
                  <thead>
                  <tr>
                    <th>Celular</th>
                    <th>Correo</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>${inputValuePhone}</td>
                    <td>${inputValueMail}</td>
                  </tr>
                  </tbody>
                </table>
                <p>Descripción: </p>
                <p>${inputValueDesc}</p>
              </div>`,
      Attachments : attachments
    }).then(
      status => {
        if (status === 'OK'){
          setSentOk(true);
        }else{
          setAllValues(true);
        }
      }
    );
  };

  const handleValidationValues = () => {
    if (inputValueName === '' || inputValueLastName === '' || inputValueMail === '' || inputValuePhone === '' || inputValueDesc ===  ''){
      setAllValues(true);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!allValues){
      handleSendMail();
      setInputValueName('');
      setInputValueLastName('');
      setInputValueMail('');
      setInputValuePhone('');
      setInputValueDesc('');
      setUploadedFiles([]);
      setAttachments([]);
      inputFile.current.value = '';
      setTimeout(() => {
        setSentOk(false);
      }, 5000);
    }
    if (allValues){
      setTimeout(() => {
        setAllValues(false);
      }, 5000);
    }
  }

  const handleUploadFiles = files =>{
    const uploaded = [...uploadedFiles];
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
      return true;
    })
    setUploadedFiles(uploaded)
  }
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    handleUploadFiles(chosenFiles);
  }

  useEffect(() => {
    setAttachments([])
    const attach = []
    
    for (let i = 0; i < uploadedFiles.length; i++) {
      const reader = new FileReader()
      reader.readAsBinaryString(uploadedFiles[i])
      reader.onload = () => {
        attach.push({
          'name': uploadedFiles[i].name,
          'data': `data:${uploadedFiles[i].type};base64,${btoa(reader.result)}`
        })
      }
      reader.onerror = () => {
        console.log('Ocurrio un error al renderizar las imagenes');
      }
    }
    setAttachments(attach);
    if (uploadedFiles.length === 0){
      inputFile.current.value = '';
    }    
  },[uploadedFiles]);

    return (
      <section className='max-w-[1140px] m-auto w-full overflow-hidden'>
        <div id='contacto' className="w-full h-[108px;]"></div>
        <div className='min-h-[150px] md:min-h-[300px] p-4 mt-12'>
          <h2 className='under-first-leter text-[var(--title-home-color)] text-left tracking-wider text-3xl uppercase'>contáctanos</h2>
          <NavLink to="https://wa.me/50240546311?text=Hola escribo de la p%C3%A1gina web, necesito informaci%C3%B3n de productos" rel="noopener noreferrer nofollow" target="_blank">
            <div className='aspect-w-16 aspect-h-9 sx:scale-150 sx:mt-3'>
              <img className='w-full h-full object-cover relative'
                  src={contactanosjpg1}
                  alt='contactanos'
              />
            </div>
          </NavLink>
        </div>
        <div className='min-h-[550px] p-4 mb-16'>
          <div className='grid md:grid-cols-2'>
            <img
              src={(name !== 'safari') ? contact : contactpng}
              alt='Contact'
              className='w-full md:h-full object-cover p-2 max-h-[500px] h-[200px]'
            />
            <form onSubmit={handleSubmit}>
              <div className='text-[var(--detail-home-color)] font-light'>
                <p>Puedes contactarnos a través del siguiente formulario o escribirnos a nuestro WhatsApp <NavLink to="https://wa.me/+50240546311" rel="noopener noreferrer nofollow" target="_blank">(+502) 4054-6311</NavLink> para más detalles de nuestros productos.</p>
              </div>
              <div className='grid grid-cols-2'>
                <input className='border m-2 p-2 text-[var(--primary-information)]' type='text' placeholder='Nombre' value={inputValueName} onChange={handleInputChangeName}/>
                <input className='border m-2 p-2 text-[var(--primary-information)]' type='text' placeholder='Apellido' value={inputValueLastName} onChange={handleInputChangeLastName}/>
                <input className='border m-2 p-2 text-[var(--primary-information)]' type='email' placeholder='Correo' value={inputValueMail} onChange={handleInputChangeMail}/>
                <input className='border m-2 p-2 text-[var(--primary-information)]' type='tel' placeholder='Celular' value={inputValuePhone} onChange={handleInputChangePhone}/>
                <input ref={inputFile} onChange={handleFileEvent} id='fileUpload' type='file' multiple accept='image/jpg, image/png' className='mx-2 px-2 text-[var(--primary-information)]'/>
                <textarea className='border col-span-2 m-2 p-2 max-h-[135px]' cols='30' rows='20' placeholder='Dinos ¿Cómo podemos ayudarte? Si lo deseas, puedes adjuntar hasta una fotografía del área en donde deseas instalar tu sombra o tu mueble.' value={inputValueDesc} onChange={handleInputChangeDesc}></textarea>
                <button onClick={handleValidationValues} className='border-solid border-2 border-[var(--primary-information)] col-span-2 m-2 text-[var(--primary-information)] hover:bg-[var(--primary-information)] hover:text-white'>
                    Enviar
                </button>
              </div>
            </form>
          </div>
          {/******* Error *********/}
          <div className={ `${allValues ? "data-[te-alert-show]:inline-flex" : "hidden"} mb-3 w-full items-center rounded-lg bg-red-300 py-5 px-6 text-base text-warning-800`} role="alert" data-te-alert-init data-te-alert-show>
            <span className="mr-1">Tienes campos vacios, por favor completa todos los campos o ha ocurrido un error.</span>
          </div>
          {/******* Good *********/}
          <div className={`${sentOk ? "data-[te-alert-show]:inline-flex" : "hidden"} mb-3 hidden w-full items-center rounded-lg bg-green-300 py-5 px-6 text-base text-warning-800`} role="alert" data-te-alert-init data-te-alert-show>
            <span className='mr-1'>Gracias por contactarnos, pronto nos comunicaremos contigo.</span>
          </div>
          {/******* Images ********/}
          <ListImagesUpload uploadImages={uploadedFiles} setUploadFiles={setUploadedFiles}/>
        </div>
      </section>
    );
  };
  
  export default Contact;